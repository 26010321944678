import { Injector, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import _isString from 'lodash/isString';
import { ImageSpecification } from '@portal/models/imageSpecification';
import { SimpleImage } from '@portal/models/simpleImage';
import { getImagesEndpoint } from '@portal/api-endpoints';
import { SafePipe, SafePipeTypes } from '@portal/core';
import ExtensionsEnum = ImageSpecification.ExtensionsEnum;
import ImageSizeEnum = ImageSpecification.ImageSizeEnum;
import { AGENT_TYPE } from '../../auth.constants';

export const DEFAULT_EXTENSIONS_PRIORITY = [
  ExtensionsEnum.Gif,
  ExtensionsEnum.Webp,
  ExtensionsEnum.Png,
  ExtensionsEnum.Jpg,
];

@Pipe({
  name: 'image',
  pure: true,
  standalone: false,
})
export class ImagePipe implements PipeTransform {
  private readonly imageApi = getImagesEndpoint(this.injector.get(AGENT_TYPE));

  constructor(private readonly domSanitizer: DomSanitizer, private readonly injector: Injector) {}

  /**
   * Transform Image object to url string with given size and extension.
   * example: "...L.jpg"
   *
   * @param value image object
   * @param s size as a key
   * @param ext array of available extensions
   * @param withSanitizeUrl sanitize url or not
   */
  transform(
    value?: SimpleImage,
    s: ImageSizeEnum = ImageSizeEnum.M,
    ext: ExtensionsEnum = ExtensionsEnum.Jpg,
    withSanitizeUrl = true,
  ): SafeUrl {
    if (!value) {
      return null;
    }

    if (_isString(value)) {
      return this.sanitizeUrl(this.imageApi + value);
    }

    const onlyOriginAvailable =
      Object.keys(value.availableSizes).length === 1 && value.availableSizes[ImageSizeEnum.ORIGINAL];
    const hasExtension = value.path.includes('.');
    if (onlyOriginAvailable && hasExtension) {
      return this.sanitizeUrl(`${this.imageApi}${value.path}`);
    }

    const extensions = value.availableSizes[s] as ExtensionsEnum[];
    const extension = this.getExtIfExist(ext, extensions) || this.getOriginalExt(value) || ext;
    const size = this.getSize(extensions, s);
    const path = `${this.imageApi}${value.path}${size}.${extension}`;

    return withSanitizeUrl ? this.sanitizeUrl(path) : path;
  }

  private getExtIfExist(extension: ExtensionsEnum, extensions?: ExtensionsEnum[]) {
    return extensions && extensions.filter(e => e === extension)[0];
  }

  private getOriginalExt(image: SimpleImage) {
    const original = extensionsByPriority(image.availableSizes[ImageSizeEnum.ORIGINAL]);

    return original && original[0];
  }

  private getSize(extensions?: ExtensionsEnum[], size?: ImageSizeEnum) {
    return (extensions && size && size !== ImageSizeEnum.ORIGINAL && `_${size}`) || '';
  }

  private sanitizeUrl(url: string): SafeResourceUrl {
    return new SafePipe(this.domSanitizer).transform(url, SafePipeTypes.Url);
  }
}

export function isSimpleImage(image?: SimpleImage | string): image is SimpleImage {
  return image.toString() !== image && !!(image as SimpleImage)?.path;
}

export function getExtensionByUrl(url: string): ExtensionsEnum {
  if (typeof url !== 'string' || !url.includes('.')) {
    return ExtensionsEnum.Jpg;
  }
  const paths: string[] = url.split('.');

  return paths[paths.length - 1] as ExtensionsEnum;
}

// extensionsPriority index is a measure of priority.
export function extensionsByPriority(extensions: string[], extensionsPriority?: string[]): ExtensionsEnum[] | string[] {
  if (!extensions || extensions.length < 1) {
    return [];
  }

  extensionsPriority = extensionsPriority || DEFAULT_EXTENSIONS_PRIORITY;
  const sortedExtensions = extensions.sort((a, b) => extensionsPriority.indexOf(a) - extensionsPriority.indexOf(b));

  return sortedExtensions?.length > 0 ? sortedExtensions : extensions;
}
