import { Pipe, PipeTransform } from '@angular/core';
import { OrderResponse } from '@portal/models/orderResponse';

@Pipe({
  name: 'unitedOrder',
  pure: true,
  standalone: false,
})
export class UnitedOrderPipe implements PipeTransform {
  transform(value: OrderResponse): string {
    if (!value) {
      return '-';
    }

    return `${value.unitedOrderId} - ${value.id} (${value.part ?? ''})`;
  }
}
