import {
  AfterViewInit,
  ComponentRef,
  Directive,
  ElementRef,
  Optional,
  Self,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { of } from 'rxjs';
import { delay, filter, switchMap, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HintComponent } from './hint/hint.component';
import { HintsService } from './hints.service';

enum ElementType {
  FormField = 'FormField',
  Title = 'Title',
  Breadcrumb = 'Breadcrumb',
  Unknown = 'Unknown',
}

@UntilDestroy()
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '.mat-subtitle-1, mat-mdc-form-field, .breadcrumb-item',
  standalone: false,
})
export class HintsDirective implements AfterViewInit {
  private elementType: ElementType;

  constructor(
    @Self() @Optional() private readonly htmlElement: TemplateRef<unknown>,
    private readonly elemRef: ElementRef<HTMLElement>,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly hintsService: HintsService,
  ) {}

  ngAfterViewInit(): void {
    const htmlElement: HTMLElement = this.elemRef.nativeElement;
    this.elementType = this.getElementType(htmlElement);
    htmlElement.style.position = 'relative';

    of(null)
      .pipe(
        delay(0), // wait until translations ready
        take(1),
        switchMap(() => [this.getTranslation(htmlElement)]),
        filter(Boolean),
        switchMap((translation: string) => this.hintsService.tryToGetLink(translation)),
        filter(Boolean),
        untilDestroyed(this),
      )
      .subscribe((link: string) => {
        const component = this.createHintComponent(this.elementType);
        component.instance.link = link;
        htmlElement.appendChild(component.location.nativeElement);
        component.changeDetectorRef.detectChanges();
      });
  }

  private getTranslation(element: HTMLElement): string {
    switch (this.elementType) {
      case ElementType.Title:
        return element.innerText;
      case ElementType.FormField:
        return element.querySelector('label')?.innerText;
      case ElementType.Breadcrumb:
        return element.innerText;
      default:
        throw new Error('Unknown element');
    }
  }

  private getElementType(element: HTMLElement): ElementType {
    if (element.classList.contains('mat-subtitle-1')) {
      return ElementType.Title;
    }

    if (element.classList.contains('mat-mdc-form-field')) {
      return ElementType.FormField;
    }

    if (element.classList.contains('breadcrumb-item')) {
      return ElementType.Breadcrumb;
    }

    return ElementType.Unknown;
  }

  private createHintComponent(elementType: ElementType): ComponentRef<HintComponent> {
    const componentRef: ComponentRef<HintComponent> = this.viewContainerRef.createComponent(HintComponent);
    if (elementType === ElementType.Title) {
      componentRef.instance.title = true;
    }

    if (elementType === ElementType.Breadcrumb) {
      componentRef.instance.breadcrumb = true;
    }

    return componentRef;
  }
}
