import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

const LENGTH_DEFAULT = 10;

@Directive({
  selector: '[portalTextCut]',
  standalone: false,
})
export class TextCutDirective implements AfterViewInit {
  @Input('portalTextCut') length: number;

  constructor(private readonly el: ElementRef, private readonly renderer: Renderer2) {}

  ngAfterViewInit(): void {
    const nativeElement: HTMLElement = this.el.nativeElement;
    const text: string = nativeElement.innerText;
    const length = this.length || LENGTH_DEFAULT;

    if (text?.length > length) {
      const textCut = `${text.slice(0, length)}...`;
      nativeElement.innerText = textCut;
      this.renderer.setAttribute(nativeElement, 'data-title', text);
    }
  }
}
