import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'portalRemoveHtmlTags',
  standalone: false,
})
export class RemoveHtmlTagsPipe implements PipeTransform {
  transform(text: string, replaceValue?: string): string {
    if (!text || typeof text !== 'string') {
      return text;
    }

    return text.replace(/(<([^>]+)>)|&ndash|&nbsp|&quot/gi, replaceValue ?? '');
  }
}
