import { Pipe, PipeTransform } from '@angular/core';
import { OrderResponse } from '@portal/models/orderResponse';
import StatusEnum = OrderResponse.StatusEnum;

@Pipe({
  name: 'orderFinalStatus',
  pure: true,
  standalone: false,
})
export class OrderFinalStatusPipe implements PipeTransform {
  transform(value: StatusEnum): boolean {
    if (!value) {
      return true;
    }

    return [StatusEnum.DELIVERED, StatusEnum.CANCELED].includes(value);
  }
}
