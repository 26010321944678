import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FullName } from '@portal/models/fullName';
import { InvoiceTemplateResponse } from '@portal/models/invoiceTemplateResponse';
import LangEnum = InvoiceTemplateResponse.LangEnum;

@Pipe({
  name: 'portalFullNamePipe',
  pure: true,
  standalone: false,
})
export class PortalFullNamePipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  transform(value?: FullName, wrongValue?: string): string | null {
    if (!value) {
      return wrongValue || '';
    }

    return this.getFullName(value);
  }

  private getFullName(value?: FullName): string {
    switch (this.translateService.currentLang.toUpperCase() as LangEnum) {
      case 'RU': {
        return ` ${value.lastName ? value.lastName + ' ' : ''}
        ${value.firstName ? value.firstName + ' ' : ''}
        ${value.middleName ? value.middleName : ''}`;
      }

      default: {
        return `${value.firstName ? value.firstName + ' ' : ''}
        ${value.lastName ? value.lastName + ' ' : ''}
        ${value.middleName ? value.middleName : ''}`;
      }
    }
  }
}
