import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ActiveNotification, NotificationType } from '../notifications.interface';

@Component({
  selector: 'portal-default-snack-bar',
  templateUrl: './default-snack-bar.component.html',
  styleUrls: ['./default-snack-bar.component.scss'],
  standalone: false,
})
export class DefaultSnackBarComponent {
  Type = NotificationType;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: ActiveNotification) {}
}
