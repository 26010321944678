import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { ActionResultBoolean } from '@portal/models/actionResultBoolean';
import { ActionResultCategoryTreeShared } from '@portal/models/actionResultCategoryTreeShared';
import { ActionResultListLong } from '@portal/models/actionResultListlong';
import type { ActionResultPageUserRegionResponse } from '@portal/models/actionResultPageUserRegionResponse';
import { TreeNodeShared } from '@portal/models/treeNodeShared';
import type { UserRegionResponse } from '@portal/models/userRegionResponse';
import { companiesClientContext, productsContext, regionsContext } from '@portal/api-endpoints';
import { DEFAULT_HUGE_PAGE_SIZE, getHttpParams, replaceId } from '@portal/core';
import CategoryTypeEnum = TreeNodeShared.CategoryTypeEnum;
import PortalDeterminateEnum = TreeNodeShared.PortalDeterminateEnum;

@Injectable({
  providedIn: 'root',
})
export class CompaniesSharedService {
  constructor(private readonly http: HttpClient) {}

  getCategories(portalDeterminate: PortalDeterminateEnum, sellerId?: number): Observable<TreeNodeShared[]> {
    const params = getHttpParams({
      portalDeterminate,
      categoryType: CategoryTypeEnum.ITEMS,
      ...(sellerId ? { sellerId } : {}),
    });

    return this.http
      .get<ActionResultCategoryTreeShared>(productsContext.allCategories, { params })
      .pipe(map(r => r.value?.treeNodes));
  }

  getSellerCategoryIds(sellerId: number): Observable<number[]> {
    return this.http
      .get<ActionResultListLong>(productsContext.sellerCategories, { params: getHttpParams({ sellerId }) })
      .pipe(map(r => r.value));
  }

  updateCategories(sellerId: number, categoryIds: number[]): Observable<ActionResultBoolean> {
    return this.http.post<ActionResultBoolean>(
      productsContext.sellerCategories,
      {
        sellerId,
        ids: categoryIds,
      }, // TODO: add SellerCategoryRequest model after models update,
      { params: getHttpParams({ sellerId }) },
    );
  }

  withdrawCheck(companyId: number): Observable<ActionResultBoolean> {
    return this.http.put<ActionResultBoolean>(replaceId(companiesClientContext.withdraw, companyId), {});
  }

  loadRegions(): Observable<UserRegionResponse[]> {
    return this.http
      .get<ActionResultPageUserRegionResponse>(regionsContext.regions, {
        params: getHttpParams({ 'page.num': 1, 'page.size': DEFAULT_HUGE_PAGE_SIZE }),
      })
      .pipe(map(res => res.value?.data));
  }
}
