import { authClientContext, companiesClientContext, usersClientContext } from '@portal/api-endpoints';

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
export const clientContext = {
  LEGAL_SELLER: `${usersClientContext.merchantUser.LEGAL_SELLER}`,
  COMMON_SELLER: `${usersClientContext.merchantUser.COMMON_SELLER}`,
  legalSellerById: `${authClientContext.seller.legalSellerById}`,
  legalBuyerById: `${authClientContext.buyer.legalBuyerById}`,
  commonSellerById: `${authClientContext.seller.commonSellerById}`,
  agentShort: `${usersClientContext.merchantUser.agentShort}`,
  companyRegions: `${usersClientContext.merchantUser.companyRegions}`,
  changePassword: `${usersClientContext.merchantUser.changePassword}`,
  initResetPassword: `${usersClientContext.merchantUser.initResetPassword}`,
  initResetPasswordAnonymous: `${usersClientContext.merchantUser.initResetPasswordAnonymous}`,
  initResetPasswordMobile: `${usersClientContext.merchantUser.initResetPasswordMobile}`,
  completeResetPasswordMobile: `${usersClientContext.merchantUser.completeResetPasswordMobile}`,
  changePasswordWithToken: `${usersClientContext.merchantUser.changePasswordWithToken}`,
  token: `${authClientContext.token.merchant}`,
  tokenWithCaptcha: `${authClientContext.token.withCaptcha}`,
  operatorInfo: `${usersClientContext.user.agent}`,
  organizationTypes: `${usersClientContext.merchantUser.organizationTypes}`,
  updateAgent: `${usersClientContext.merchantUser.updateAgent}`,
  updateOperator: `${usersClientContext.user.updateAgent}`,
  updateLogo: `${companiesClientContext.self.updateLogo}`,
  updateCompany: `${usersClientContext.merchantUser.updateCompany}`,
  updateCompanyById: `${companiesClientContext.update}`,
  roles: `${usersClientContext.user.roles}`,
};
