import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AppType } from '../builder.interface';
import { builderEnvironment } from '../environment/environment';

@Directive({
  selector: '[portalHideInApp]',
  standalone: false,
})
export class HideInAppDirective {
  @Input()
  set portalHideInApp(v: AppType[]) {
    if (!v.includes(builderEnvironment.appType)) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);

      return;
    }
    this.viewContainerRef.clear();
  }

  constructor(private readonly templateRef: TemplateRef<object>, private readonly viewContainerRef: ViewContainerRef) {}
}
