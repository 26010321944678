import { Coordinates } from '@portal/models/coordinates';
import { Item } from '@portal/models/item';
import { OrderResponse } from '@portal/models/orderResponse';
import { PageContentBlockV2Shared } from '@portal/models/pageContentBlockV2Shared';
import { PromocodePaginationRequest } from '@portal/models/promocodePaginationRequest';
import { RoleSaveRequest } from '@portal/models/roleSaveRequest';
import { SkuItemShared } from '@portal/models/skuItemShared';
import ProductTypeEnum = Item.ProductTypeEnum;
import StatusEnum = OrderResponse.StatusEnum;
import ElementTypeEnum = PageContentBlockV2Shared.ElementTypeEnum;
import LangEnum = PromocodePaginationRequest.LangEnum;
import ProfileTypeEnum = RoleSaveRequest.ProfileTypeEnum;
import CurrencyEnum = SkuItemShared.CurrencyEnum;

export enum AppType {
  Gdesemena = 'Gdesemena',
  Safal = 'Safal',
  Rshb = 'RSHB',
  Stc = 'STC',
}

export enum LanguageLocale {
  en = 'en',
  ru = 'ru',
  fr = 'fr',
  ar = 'ar',
  hi = 'hi',
  khr = 'khr',
  cny = 'cny',
}

export enum Theme {
  DEFAULT = 'theme-default',
  STC = 'theme-stc',
  PHEMA = 'theme-phema',
  LILAC = 'theme-lilac',
  COMMON = 'theme-common',
}

export interface LogoConfig {
  normal: string;
  light?: string;
  small?: string;
}

export interface BuilderEnvironment extends CommonBuilderSettings {
  appType: AppType;
  locale: LanguageLocale;
  assets: AssetsBuilderSettings;
  recaptcha?: string;
  theme?: Theme;
}

export interface BuilderSettings extends CommonBuilderSettings {
  name?: string;
  id?: number;
}

interface AssetsBuilderSettings {
  logo: {
    sidebar: LogoConfig;
    authPage: LogoConfig;
  };
  authBackground?: string;
}

export interface CommonBuilderSettings {
  langSetting: LangSettings;
  captchaSettings?: CaptchaSettings;
  country: string;
  currency: CurrencyEnum;
  currencySymbol: string;
  currencySettings: CurrencySettings[];
  phoneSettings: PhoneSettings[];
  systemLang: LangEnum;
  timeOut: number;
  loginType?: LoginType;
  currencyDisplayType?: CurrencyDisplayType;
  passwordPattern?: string;
  statusesMap?: Record<StatusEnum, StatusEnum[]>;
  includeDeliveryZoneAddress?: boolean;
  verifyPhone?: boolean;
  termsOfUseUrl?: string;
  privacyPolicyUrl?: string;
  enableMarginNegotiation?: boolean;
  allowedProductTypes?: ProductTypeEnum[];
  defaultCoordinates?: Coordinates;
  accessSettlement?: boolean;
  accessTieredPrices?: boolean;
  onlyEmailPasswordReset?: boolean;
  allowSelfSignUp?: boolean;
  signUpInfo?: SignUpInfo;
  disableCommonLogin?: boolean;
  disabledProfiles?: ProfileTypeEnum[];
  pageBuilderElements?: ElementTypeEnum[];
  manageManualDelivery?: boolean;
  merchantHelpUrl?: string;
  operatorHelpUrl?: string;
  allowIndividualPrices?: boolean;
  manageSellerRelatedItems?: RelatedItemPermissionLevel;
  isB2b?: boolean;
  disableSkuRemove?: boolean;
  disableSharing?: boolean;
  excludeProductConversion?: boolean;
  hideAgreedToReplace?: boolean;
  extraI18nMap?: Record<string, string>;
  useExternalMenu?: boolean;
  disableFutureProducts?: boolean;
  themeColors?: {
    primary: string;
    warn: string;
    accent: string;
  };
  multiSkusForTenderProducts?: boolean;
  excludeCourier?: boolean;
  ignoreUserCords?: boolean;
  allowBuyerProfile?: string;
}

export enum RelatedItemPermissionLevel {
  Product = 'product',
  Bulk = 'bulk',
}

export interface PhoneSettings {
  phonePrefix: number;
  phoneLength: number;
  active?: boolean;
}

export interface SignUpInfo {
  email: string;
  phone: string;
}

export interface CurrencySettings {
  currency: CurrencyEnum;
  conversionRatio: number;
  active?: boolean;
  format: string;
  currencySymbol: string;
}

export interface LangSettings {
  defaultLang: LangEnum;
  availableLangs: LangEnum[];
}

export enum CurrencyDisplayType {
  CODE = 'code',
  SYMBOL = 'symbol',
  SYMBOL_NARROW = 'symbol-narrow',
}

interface CaptchaSettings {
  enabled?: boolean;
  publicKey?: string;
  mobilePublicKey?: string;
}

export type LoginType = 'PHONE' | 'EMAIL';
export enum LoginTypeEnum {
  Phone = 'PHONE',
  Email = 'EMAIL',
}
