import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { RoleShared } from '@portal/models/roleShared';
import { PermissionsService } from './permissions.service';
import PrivilegesEnum = RoleShared.PrivilegesEnum;

@Directive({
  selector: '[portalCanAccess]',
  standalone: false,
})
export class CanAccessDirective implements OnInit {
  @Input() portalCanAccess: PrivilegesEnum[];

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly permissionsService: PermissionsService,
  ) {}

  ngOnInit(): void {
    this.checkPermissions();
  }

  private checkPermissions(): void {
    const allowed = this.portalCanAccess.every(p => this.permissionsService.hasPermission(p));
    if (allowed) {
      this.viewContainer.createEmbeddedView(this.templateRef);

      return;
    }

    this.viewContainer.clear();
  }
}
