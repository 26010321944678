import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'portalProfile',
  pure: true,
  standalone: false,
})
export class PortalProfilePipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  transform(value: string): Observable<string> {
    return this.translateService
      .get('orders.buyerType')
      .pipe(map((profileType: Record<string, string>) => profileType[value] ?? value));
  }
}
