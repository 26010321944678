import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RoleShared } from '@portal/models/roleShared';
import { UserInfo } from '@portal/models/userInfo';
import { BuilderInfoService } from '@portal/builder';
import { AuthService } from '../auth.service';
import { PermissionsFullAccess, PermissionsMap, PermissionsRead, PermissionsUpdate } from './permissions.interface';
import PrivilegesEnum = RoleShared.PrivilegesEnum;
import ProfileTypeEnum = UserInfo.ProfileTypeEnum;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  protected permissionsMap: PermissionsMap;
  protected profileType: ProfileTypeEnum;

  constructor(private readonly auth: AuthService, private readonly builderInfoService: BuilderInfoService) {
    this.auth
      .getUserInfo()
      .pipe(
        filter(info => !!info),
        untilDestroyed(this),
      )
      .subscribe(i => {
        if (!i.privileges) {
          return;
        }
        this.permissionsMap = this.getPermissionsMap(i.privileges);
        this.profileType = i.profileType;
      });
  }

  get profilePermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.MERCHANTPROFILEREAD),
      edit: this.hasPermission(PrivilegesEnum.MERCHANTPROFILEWRITE),
    };
  }

  // TODO add real permissions check
  get dashboardPermissions(): PermissionsUpdate {
    return {
      view: true,
      edit: true,
    };
  }

  get companiesPermissions(): PermissionsFullAccess {
    return {
      view: this.hasPermission(PrivilegesEnum.COMPANIESREAD),
      create: this.hasPermission(PrivilegesEnum.COMPANIESWRITE),
      edit: this.hasPermission(PrivilegesEnum.COMPANIESWRITE),
      delete: this.hasPermission(PrivilegesEnum.COMPANIESWRITE),
    };
  }

  get merchantCompanyPermissions(): PermissionsFullAccess {
    return {
      view: this.hasPermission(PrivilegesEnum.MERCHANTCOMPANYWRITE),
      create: this.hasPermission(PrivilegesEnum.MERCHANTCOMPANYWRITE),
      edit: this.hasPermission(PrivilegesEnum.MERCHANTCOMPANYWRITE),
      delete: this.hasPermission(PrivilegesEnum.MERCHANTCOMPANYWRITE),
    };
  }

  get membersPermissions(): PermissionsFullAccess {
    return {
      view: this.hasPermission(PrivilegesEnum.MEMBERSREAD),
      create: this.hasPermission(PrivilegesEnum.MEMBERSWRITE),
      delete: this.hasPermission(PrivilegesEnum.MEMBERSWRITE),
      edit: this.hasPermission(PrivilegesEnum.MEMBERSWRITE),
    };
  }

  get usersPermissions(): PermissionsFullAccess {
    return {
      view: this.hasPermission(PrivilegesEnum.CLIENTSREAD),
      edit: this.hasPermission(PrivilegesEnum.CLIENTSWRITE),
      create: this.hasPermission(PrivilegesEnum.CLIENTSWRITE),
      delete: this.hasPermission(PrivilegesEnum.CLIENTSWRITE),
    };
  }

  get productPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.ITEMSREAD),
      edit: this.hasPermission(PrivilegesEnum.ITEMSWRITE),
    };
  }

  get orderPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.ORDERREAD),
      edit: this.hasPermission(PrivilegesEnum.ORDERWRITE),
    };
  }

  get deliveryOrdersPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.DELIVERYORDERSREAD),
      edit: this.hasPermission(PrivilegesEnum.DELIVERYORDERSWRITE),
    };
  }

  get orderSumPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.ORDERSUMWRITE),
      edit: this.hasPermission(PrivilegesEnum.ORDERSUMWRITE),
    };
  }

  get returnsPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.ORDERRETURNSREAD),
      edit: this.hasPermission(PrivilegesEnum.ORDERRETURNSWRITE),
    };
  }

  // TODO: add actual permissions
  get requestsForQuotations(): PermissionsUpdate {
    return {
      view: true,
      edit: true,
    };
  }

  get requestsPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.REQUESTSREAD),
      edit: this.hasPermission(PrivilegesEnum.REQUESTSWRITE),
    };
  }

  get reportsPermissions(): PermissionsRead {
    return {
      view: this.hasPermission(PrivilegesEnum.LOGSREPORTREAD),
    };
  }

  get pagesPermissions(): PermissionsFullAccess {
    return {
      view: this.hasPermission(PrivilegesEnum.PAGESREAD),
      edit: this.hasPermission(PrivilegesEnum.PAGESWRITE),
      delete: this.hasPermission(PrivilegesEnum.PAGESWRITE),
      create: this.hasPermission(PrivilegesEnum.PAGESWRITE),
    };
  }

  get financialProductsPermissions(): PermissionsFullAccess {
    return {
      view: this.hasPermission(PrivilegesEnum.FINPRODUCTSREAD),
      edit: this.hasPermission(PrivilegesEnum.FINPRODUCTSWRITE),
      delete: this.hasPermission(PrivilegesEnum.FINPRODUCTSWRITE),
      create: this.hasPermission(PrivilegesEnum.FINPRODUCTSWRITE),
    };
  }

  get paymentsPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.PAYMENTPLANSREAD),
      edit: this.hasPermission(PrivilegesEnum.PAYMENTPLANSWRITE),
    };
  }

  get notificationsPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.NOTIFICATIONSREAD),
      edit: this.hasPermission(PrivilegesEnum.NOTIFICATIONSWRITE),
    };
  }

  get userNotificationsPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.TARGETNOTIFICATIONSREAD),
      edit: this.hasPermission(PrivilegesEnum.TARGETNOTIFICATIONSWRITE),
    };
  }

  get groupsPermissions(): PermissionsUpdate {
    return {
      view: true,
      edit: true,
    };
  }

  get templatesPermissions(): PermissionsFullAccess {
    return {
      view: this.hasPermission(PrivilegesEnum.TEMPLATESREAD),
      edit: this.hasPermission(PrivilegesEnum.TEMPLATEWRITE),
      delete: this.hasPermission(PrivilegesEnum.TEMPLATEWRITE),
      create: this.hasPermission(PrivilegesEnum.TEMPLATEWRITE),
    };
  }

  get articlesPermissions(): PermissionsFullAccess {
    return {
      view: this.hasPermission(PrivilegesEnum.ARTICLESREAD),
      edit: this.hasPermission(PrivilegesEnum.ARTICLESWRITE),
      delete: this.hasPermission(PrivilegesEnum.ARTICLESWRITE),
      create: this.hasPermission(PrivilegesEnum.ARTICLESWRITE),
    };
  }

  get auditPermissions(): PermissionsRead {
    return {
      view: this.hasPermission(PrivilegesEnum.AUDITOPERATORSREAD) || this.hasPermission(PrivilegesEnum.AUDITUSERSREAD),
    };
  }

  get auditActionsPermissions() {
    return {
      operators: this.hasPermission(PrivilegesEnum.AUDITOPERATORSREAD),
      users: this.hasPermission(PrivilegesEnum.AUDITUSERSREAD),
    };
  }

  get userProfilePermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.USERPROFILEREAD),
      edit: this.hasPermission(PrivilegesEnum.USERPROFILEWRITE),
    };
  }

  get dynamicBlocksPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.BLOCKSREAD),
      edit: this.hasPermission(PrivilegesEnum.BLOCKSWRITE),
    };
  }

  // TODO specify its own permission
  get pageBuilderPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.BLOCKSREAD),
      edit: this.hasPermission(PrivilegesEnum.BLOCKSWRITE),
    };
  }

  get seoTagsPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.PAGESEOTAGSREAD),
      edit: this.hasPermission(PrivilegesEnum.PAGESEOTAGSWRITE),
    };
  }

  get settingsPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.MARKETPLACESETTINGSREAD),
      edit: this.hasPermission(PrivilegesEnum.MARKETPLACESETTINGSWRITE),
    };
  }

  get rolesPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.ROLESREAD),
      edit: this.hasPermission(PrivilegesEnum.ROLESWRITE),
    };
  }

  get operatorsPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.OPERATORSREAD),
      edit: this.hasPermission(PrivilegesEnum.OPERATORSWRITE),
    };
  }

  get operatorSettingsPermissions(): PermissionsUpdate {
    return {
      view: true,
      edit: true,
    };
  }

  get mediaPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.MEDIAREAD),
      edit: this.hasPermission(PrivilegesEnum.MEDIAWRITE),
    };
  }

  get reviewsPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.REVIEWSREAD),
      edit: this.hasPermission(PrivilegesEnum.REVIEWTEMPLATESWRITE),
    };
  }

  get reviewsSettingsPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.REVIEWTEMPLATESREAD),
      edit: this.hasPermission(PrivilegesEnum.REVIEWTEMPLATESWRITE),
    };
  }

  get promoCodesPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.PROMOCODESREAD),
      edit: this.hasPermission(PrivilegesEnum.PROMOCODESWRITE),
    };
  }

  get marketingCompaniesPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.MARKETINGCOMPANIESREAD),
      edit: this.hasPermission(PrivilegesEnum.MARKETINGCOMPANIESWRITE),
    };
  }

  get tagsPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.TAGSREAD),
      edit: this.hasPermission(PrivilegesEnum.TAGSWRITE),
    };
  }

  get negotiationsPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.NEGOTIATIONSREAD),
      edit: this.hasPermission(PrivilegesEnum.NEGOTIATIONSWRITE),
    };
  }

  get partnersPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.PARTNERPRODUCTREAD),
      edit: this.hasPermission(PrivilegesEnum.PARTNERPRODUCTWRITE),
    };
  }

  get regionsPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.USERREGIONSREAD),
      edit: this.hasPermission(PrivilegesEnum.USERREGIONSWRITE),
    };
  }

  get branchesPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.BRANCHESREAD),
      edit: this.hasPermission(PrivilegesEnum.BRANCHESWRITE),
    };
  }

  get disputesPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.DISPUTESREAD),
      edit: this.hasPermission(PrivilegesEnum.DISPUTESWRITE),
    };
  }

  get categoriesPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.CATEGORYREAD),
      edit: this.hasPermission(PrivilegesEnum.CATEGORYWRITE),
    };
  }

  get categoriesFeePermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.CATEGORYCOMMISSIONREAD),
      edit: this.hasPermission(PrivilegesEnum.CATEGORYCOMMISSIONWRITE),
    };
  }

  get invoicesPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.INVOICESTEMPLATESREAD),
      edit: this.hasPermission(PrivilegesEnum.INVOICESTEMPLATESWRITE),
    };
  }

  get settlementPermissions(): PermissionsUpdate {
    return {
      view: this.builderInfoService.settings?.accessSettlement || false,
      edit: this.builderInfoService.settings?.accessSettlement || false,
    };
  }

  get tendersOffersPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.TENDEROFFERSREAD),
      edit: this.hasPermission(PrivilegesEnum.TENDEROFFERSWRITE),
    };
  }

  get tendersPermissions(): PermissionsFullAccess {
    return {
      view: this.hasPermission(PrivilegesEnum.TENDERSREAD),
      edit: this.hasPermission(PrivilegesEnum.TENDERSWRITE),
      delete: this.hasPermission(PrivilegesEnum.TENDERSWRITE),
      create: this.hasPermission(PrivilegesEnum.TENDERSWRITE),
    };
  }

  get tieredPricesPermissions(): PermissionsUpdate {
    return {
      view: !!this.builderInfoService.settings?.accessTieredPrices,
      edit: !!this.builderInfoService.settings?.accessTieredPrices,
    };
  }

  get sourcingInputsPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.SOURCINGINPUTSREAD),
      edit: this.hasPermission(PrivilegesEnum.SOURCINGINPUTSWRITE),
    };
  }

  get sourcingOutputsPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.SOURCINGOUTPUTSREAD),
      edit: this.hasPermission(PrivilegesEnum.SOURCINGOUTPUTSWRITE),
    };
  }

  get fdsPricesPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.FDSREAD),
      edit: this.hasPermission(PrivilegesEnum.FDSWRITE),
    };
  }

  get deliveryPeriodsPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.DELIVERYPERIODSREAD),
      edit: this.hasPermission(PrivilegesEnum.DELIVERYPERIODSWRITE),
    };
  }

  get paymentAttributesPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.PAYMENTATTRIBUTESREAD),
      edit: this.hasPermission(PrivilegesEnum.PAYMENTATTRIBUTESWRITE),
    };
  }

  get licensesPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.LICENSESREAD),
      edit: this.hasPermission(PrivilegesEnum.LICENSESWRITE),
    };
  }

  get seoPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.SEOREAD),
      edit: this.hasPermission(PrivilegesEnum.SEOWRITE),
    };
  }

  get relatedItemsPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.RELATEDITEMSREAD),
      edit: this.hasPermission(PrivilegesEnum.RELATEDITEMSWRITE),
    };
  }

  get promotionsPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.PROMOTIONSREAD),
      edit: this.hasPermission(PrivilegesEnum.PROMOTIONSWRITE),
    };
  }

  get refundsPermissions(): PermissionsUpdate {
    return {
      view: this.hasPermission(PrivilegesEnum.REFUNDSREAD),
      edit: this.hasPermission(PrivilegesEnum.REFUNDSWRITE),
    };
  }

  hasPermission(flag: PrivilegesEnum) {
    return !!this.permissionsMap?.[flag];
  }

  protected getPermissionsMap(permissions: PrivilegesEnum[]): PermissionsMap {
    return permissions.reduce((acc, cur) => ((acc[cur] = cur), acc), {} as PermissionsMap);
  }
}
