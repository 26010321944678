const api = '/chat-service/api/external';

export const wsChatsContext = {
  chats: `${api}/chats`,
  chat: `${api}/chats/{id}`,
  summary: `${api}/chats/summary`,
  sendMessage: `/app/chats/{id}/message/create`,
  chatIdTopic: `/topic/chats/{id}/message/create/event`,
  chatIdErrorsTopic: `/topic/chats/{id}/message/create/check-restrictions/event`,
  readMessages: '/app/chats/{id}/message/read',
  uploadAttachment: `${api}/chats/attachments`,
};
