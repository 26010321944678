import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OrderResponse } from '@portal/models/orderResponse';
import { BuilderInfoService } from '@portal/builder';
import StatusEnum = OrderResponse.StatusEnum;

@Pipe({
  name: 'orderStatusPreTranslate',
  pure: true,
  standalone: false,
})
export class OrderStatusPreTranslatePipe implements PipeTransform {
  private readonly asyncPipe: AsyncPipe;

  constructor(
    cdr: ChangeDetectorRef,
    private readonly translateService: TranslateService,
    private readonly builderInfoService: BuilderInfoService,
  ) {
    this.asyncPipe = new AsyncPipe(cdr);
  }

  transform(value: StatusEnum): string {
    const settings = this.builderInfoService.settings;
    const statusMapping: Record<StatusEnum, StatusEnum[]> = settings?.statusesMap;

    let translateValue = null;
    if (Object.values(statusMapping || {}).some((v: StatusEnum[]) => v.includes(value))) {
      translateValue = Object.entries(statusMapping).find(([_, v]) => v.includes(value));
    }

    return `orders.status.${translateValue?.[0] || value}`;
  }
}
