import { Directive, HostBinding, OnInit } from '@angular/core';
import { BuilderInfoService } from '../shared';

@Directive({
  selector: '[portalDirection]',
  standalone: false,
})
export class DirectionDirective implements OnInit {
  isRtl = false;

  @HostBinding('class.rtl')
  get rtl(): boolean {
    return this.isRtl;
  }

  constructor(private readonly builderInfoService: BuilderInfoService) {}

  ngOnInit(): void {
    this.isRtl = this.builderInfoService.interfaceDirection === 'rtl';
  }
}
