import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'portalPricePerItem',
  standalone: false,
})
export class PricePerItemPipe implements PipeTransform {
  transform(totalPrice: number, quantity: number, fractionDigits: number = 2): number | string {
    if (!totalPrice || !quantity) {
      return 0;
    }

    return (totalPrice / quantity).toFixed(fractionDigits);
  }
}
