import { Pipe, PipeTransform } from '@angular/core';
import { BuilderInfoService } from '@portal/builder';

@Pipe({
  name: 'portalLang',
  standalone: false,
})
export class PortalLangPipe implements PipeTransform {
  constructor(private readonly builderInfoService: BuilderInfoService) {}

  transform(value: Record<string, string>): string | null {
    return value?.[this.builderInfoService.lang];
  }
}
